import React from "react";
import blackRibbon from "../components/Images/black-ribbon-5704823_1280.png";

function Detail(props) {
  if (props.detailInfo === "defunct") {
    return (
      <img className="blackRibbon-img" src={blackRibbon} alt="Black Ribbon" />
    );
  } else {
    return <p className="info">{props.detailInfo}</p>;
  }
}

export default Detail;
