import foto1 from "./components/Fotos/foto1.jpg";
import foto2 from "./components/Fotos/foto2.jpg";
import foto3 from "./components/Fotos/foto3.jpg";
import foto4 from "./components/Fotos/foto4.jpg";
import foto5 from "./components/Fotos/foto5.jpg";

const bachilleres = [
  {
    id: 1,
    name: "Juan Antonio Abellòn Laplace",
    imgURL: foto1,
    phone: "telephone number line 1234567890-1234567890",
    email: "email address line 1234567890-1234567890",
    DOB: "Date Of Birth line 1234567890",
  },
  {
    id: 2,
    name: "Juan Francisco Mariano Aguilar Bustamante",
    imgURL: foto2,
    phone: "defunct",
    email: "",
    DOB: "Date Of Birth and Date Of Death line 1234567890",
  },
  {
    id: 3,
    name: "Enrique Javier Aguilar Samayoa",
    imgURL: foto3,
    phone: "",
    email: "solamente email",
    DOB: "",
  },
  {
    id: 4,
    name: "Raùl Antonio Alfaro Arce",
    imgURL: foto4,
    phone: "solamente numero de telefono",
    email: "",
    DOB: "",
  },
  {
    id: 5,
    name: "Josè Roberto Alvarez Mena",
    imgURL: foto5,
    phone: "defunct",
    email: "",
    DOB: "",
  },
];

export default bachilleres;
